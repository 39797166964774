import React, { useState } from 'react';
import './FullStackJavaDeveloper.css';

const FullStackJavaDeveloper = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/full_stack_java_developer/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // full stack java developer page
    <div className="java-description-container">
      {/* full stack java developer description */}
      <div className="java-details">
        <h2>Full Stack Java Developer</h2>
        <h3>Responsibilities</h3>
        {/* full stack java developer responsibilities */}
        <div className='java-description'>
          <p><b>Design and Development:</b> Create robust, scalable, and high-performance web applications using Java technologies for both frontend and backend development.</p>
          <p><b>Database Management:</b> Design, implement, and maintain databases, ensuring data integrity and efficient retrieval.</p>
          <p><b>Integration:</b> Develop and integrate APIs and third-party services, ensuring seamless communication between different application components.</p>
          <p><b>Testing and Debugging:</b> Conduct thorough testing and debugging of applications to ensure functionality, performance, and security standards are met.</p>
          <p><b>Collaboration:</b> Work closely with cross-functional teams, including designers, product managers, and other developers, to deliver high-quality software solutions.</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* full stack java developer education skills */}
        <div className='java-description'>
          <p><b>Bachelor's Degree in Computer Science or Related Field:</b> Essential for a solid foundation in programming principles and software development.</p>
          <p><b>Proficiency in Java Programming:</b> Deep understanding of Java, including its frameworks and libraries, is crucial..</p>
          <p><b>Knowledge of Frontend Technologies:</b> Skills in HTML, CSS, JavaScript, and modern frontend frameworks (e.g., React, Angular) are necessary for building user interfaces.</p>
          <p><b>Experience with Databases:</b> Familiarity with relational databases (e.g., MySQL, PostgreSQL) and NoSQL databases (e.g., MongoDB) is required for backend data management.</p>
          <p><b>Understanding of Web Services and APIs:</b> Ability to design, develop, and integrate RESTful APIs and web services is critical for enabling communication between frontend and backend systems.</p>
        </div>
        {/* full stack java developer additional description */}
        <div className='java-description-info'>
            <p><b>Job Category:</b>Full Stack Java Developer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Musrambagh</p>
        </div>
      </div>
      {/* full stack java developer apply form */}
      <div className="java-apply-form">
        <h3>Apply for this position</h3>
        {/* full stack java developer apply form */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name" required />
          
          <label>Email *</label>
          <input type="email" name="email" required />
          
          <label>Phone *</label>
          <input type="tel" name="phone" required />
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4" required></textarea>

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required />
          
          <button type="submit">Submit</button>
        </form>
        {/* Display response message */}
        {responseMessage && (
            <div className="response-message">
                <p>{responseMessage}</p>
            </div>
            )}
      </div>
    </div>
  )
}

export default FullStackJavaDeveloper
