import React from 'react';
import './ClinicalData.css';
import dashboard1  from '../../Assets/dashboard_screenshot1.png';
import dashboard2  from '../../Assets/dashboard_screenshot2.png';
import dashboard3 from '../../Assets/dashboard_screenshot3.png';
import historicaldata from '../../Assets/historical_data_viewing.png';
import zoomfunctionality from '../../Assets/zoom_functionality.png';
import dateselection from '../../Assets/date_selection.png';
import generateddashboard from '../../Assets/generated_dashboard.png';
import generatedgraph from '../../Assets/generated_graph.png';

const ClinicalData = () => {
  return (
    // dashboard-container page
    <div className='dashboard-container'>
        {/* dashboard-sub-container */}
        <div className="dashboard-sub-container slide-right">
            <h4>Dashboard Functionality:</h4>
            {/* sub-container1 */}
            <div className='sub-container1 fade-in'>
                <p>The dashboard functionality encompasses real-time representation and monitoring of data acquired from sensor devices. 
                    It provides users with an intuitive interface to visualize key parameters such as Humidity, Temperature, Gas Resistance, 
                    and Indoor Air Quality (IAQ). Each parameter is displayed dynamically, reflecting the most recent readings obtained from the sensor devices. </p>
    
                <p><h3>Real-time data visualization: </h3>The dashboard displays live data, ensuring that users have access to up-to-date information regarding environmental 
                conditions. This real-time aspect is crucial for making timely decisions and responding promptly to changes in the monitored environment.</p>
                <p><h3>Parameter Visualization: </h3>The parameters of interest, including Humidity, Temperature, Gas Resistance, and Indoor Air Quality, are prominently 
                featured on the dashboard. Each parameter is visualized using appropriate graphical representations such as line charts, gauges, or histograms, providing 
                users with clear and easily interpretable insights into environmental conditions.</p>
                <img src={dashboard1} alt="dashboard1" />  
            </div>
            {/* sub-container2 */}
            <div className='sub-container2 slide-left'>
                <p><h3>User Interaction: </h3>The dashboard may include interactive elements that enable users to interact with the displayed data. For example, users may 
                have the ability to zoom in on specific time periods, toggle between different sensor devices, or adjust thresholds for parameter alerts. These interactive 
                features empower users to explore the data in-depth and derive meaningful insights relevant to their monitoring objectives</p>
                <p><h3>Threshold Alerts:</h3>Threshold alerts are an essential feature of the dashboard, providing users with timely notifications when parameter values deviate 
                from predefined ranges. This functionality enhances situational awareness and enables proactive responses to potential issues in the monitored environment</p>
                <p><h3>Predefined Threshold Ranges: </h3>Each parameter, such as Humidity, Temperature, Gas Resistance, and Indoor Air Quality, is associated with predefined 
                threshold ranges that represent acceptable limits for normal operation. These ranges are determined based on industry standards, regulatory requirements, or 
                specific user preferences, ensuring that deviations from normal conditions are promptly identified.</p>
                <img src={dashboard2} alt="dashboard2" />
            </div>
            {/* sub-container3 */}
            <div className='sub-container3 fade-in'>
                <p><h3>Visual Indication: </h3>When a parameter value exceeds its predefined threshold range, the corresponding indication on the dashboard changes to red. This 
                visual cue immediately draws the user's attention to the parameter that has exceeded its limits, signaling a potential issue that requires attention. The use of 
                color-coded alerts enhances clarity and facilitates rapid identification of abnormal conditions.</p>
                <p><h3>Significance of Red Alerts: </h3>Red alerts signify deviations from normal operating conditions that may indicate potential problems or risks. For example, 
                a high temperature reading could indicate equipment malfunction or overheating, while elevated gas resistance levels might suggest air quality issues or 
                environmental hazards. By highlighting these deviations in red, users are prompted to investigate further and take appropriate corrective actions to address the 
                underlying issues.</p>
                <p><h3>Actionable Insights: </h3>Threshold alerts empower users to proactively monitor and manage the monitored environment by providing actionable insights into 
                emerging issues. By promptly addressing deviations from normal conditions, users can mitigate risks, optimize performance, and ensure the safety and well-being of 
                occupants or assets</p>
                <img src={dashboard3} alt="dashboard3" />
            </div>
        </div>
        {/* dashboard-sub-container2 */}
        <div className='dashboard-sub-container2 slide-right'>
            <h2>HISTORICAL DATA</h2>
            <p>Our dashboard offers users comprehensive capabilities to analyze historical data and gain insights into sensor parameters over a specified time range. Key 
                functionalities include the ability to view data for up to the last two days, zoom in on graphs for precise value examination, and obtain maximum and minimum 
                values for each parameter per day.</p>
            <p className='historical_data'><h3>Historical Data Viewing: </h3>Users can select a time range spanning up to the last two days to visualize historical data trends. This feature enables 
            retrospective analysis and comparison of sensor parameters over a defined period, facilitating informed decision-making and trend identification.</p> 
            <img className='historical_data_img' src={historicaldata} alt="historicaldata" />   
            <p className='zoom_functionality'><h3>Zoom Functionality: </h3>The dashboard allows users to zoom in on graphs to examine data points with greater precision. By zooming in, users can focus on 
            specific time intervals and explore data fluctuations in detail, enhancing their understanding of sensor parameter variations and potential patterns.</p>
            <img className='zoom_functionality_img' src={zoomfunctionality} alt="zoomfunctionality" />
            <p className='dateselection'><h3>Max and Min Value Display: </h3> Upon selecting specific dates within the dashboard, users can access maximum and minimum values for each sensor parameter 
            recorded on the chosen dates. This feature provides users with valuable insights into daily parameter fluctuations and extremes, aiding in anomaly detection and 
            trend analysis.</p>
            <img className='dateselection_img' src={dateselection} alt="dateselection" />
        </div>
        {/* dashboard-sub-container3 */}
        <div className='dashboard-sub-container3 fade-in'>
            <img src={generateddashboard} alt="generateddashboard" />
            <p><h3>Graphical Representation for Each Sensor: </h3>The dashboard presents graphical representations for each sensor parameter, offering visual insights into 
            parameter trends and variations over time. By providing separate graphs for each sensor parameter, users can easily compare and contrast data across different 
            parameters, facilitating comprehensive analysis and interpretation.</p>
            <img src={generatedgraph} alt="generatedgraph" />
        </div>
    </div>
  )
}

export default ClinicalData
