import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileForGoogleLogin = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user information from localStorage
        localStorage.removeItem('email');
        localStorage.removeItem('username');

        // Redirect to the login page
        navigate('/account');
    };

    // Retrieve user information from localStorage
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username'); // Retrieve username from localStorage

    return (
        <div className="profile">
            <h1>Profile </h1>
            <h1><strong>Username:</strong> {username || 'Username not found'}</h1>
            <h1><strong>Email:</strong> {email || 'Email not found'}</h1><br /><br />
            <button onClick={handleLogout}>Logout</button><br /><br />
        </div>
    );
};

export default ProfileForGoogleLogin;
