import React from 'react';
import './AboutUs.css';
import aboutus from '../../Assets/about-us.avif';

const AboutUs = () => {
    return (
        // AboutUs page container
        <div className="about-us">
          {/* AboutUs box content */}
          <div className="about-us-container">
            {/* AboutUs text content */}
            <h1>About Us</h1>
            {/* AboutUs image */}
            <img src={aboutus} alt="about-us" />
            {/* AboutUs text content */}
            <div className="about-us-text">
                {/* AboutUs title */}
              <h3>Our Mission</h3>
              {/* AboutUs description */}
              <p>At Embed-View, we are committed to providing innovative hardware and software solutions to our clients. 
              Our end-to-end service provision ensures that our clients receive tailored solutions that integrate sensor
              data collection, cloud-based storage, and visualization. Our mission is to help our clients achieve their 
              goals through cutting-edge technology solutions.</p>
            </div>
          </div>
          {/* AboutUs content wrapper */}
          <div className="content-wrapper"> 
              {/* AboutUs boxes content */}         
              <div className="box box-1">
                  {/* AboutUs story title */}
                  <div className="first">
                      <h2>Our Story</h2>
                  </div>
                  {/* AboutUs story description */}
                  <div className="second">
                      <p>
                      Embed-View was founded with the goal of providing customized technology solutions to businesses. Our 
                      team of experts has years of experience in hardware and software development, and we have helped numerous 
                      clients achieve success through our innovative solutions. We pride ourselves on our ability 
                      to understand our clients' unique needs and provide tailored solutions that exceed their 
                      expectations. Let us help you take your business to the next level.</p>
                  </div>
              </div>
              {/* AboutUs boxes content */}
              <div className="box box-2">
                  {/* AboutUs leadership title */}
                  <div className="first">
                      <h2>Experienced Leadership</h2>
                  </div>
                  {/* AboutUs leadership description */}
                  <div className="second">
                      <p>
                      Our team of experienced professionals is dedicated to providing the highest quality service to our clients. 
                      With years of experience in hardware and software development, we have the expertise to meet even the most 
                      complex needs. We work closely with our clients to understand their unique requirements and provide customized 
                      solutions that meet their goals. At Embed-View, we believe that our people are our greatest asset.</p>
                  </div>
              </div>
          </div>
        </div>
    );
};

export default AboutUs;
