import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { IoMdClose } from 'react-icons/io'; 
import { RxHamburgerMenu } from 'react-icons/rx'; 
import './Navbar.css';
import logo from '../../Assets/embed_view_logo.png';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Add or remove the sticky class based on scroll position
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // Functions to handle mouse events
  const handleMouseEnter = (menu) => {
    if (menu === 'products') setProductsOpen(true);
    if (menu === 'services') setServicesOpen(true);
    if (menu === 'more') setMoreOpen(true);
  };

  const handleMouseLeave = (menu) => {
    if (menu === 'products') setProductsOpen(false);
    if (menu === 'services') setServicesOpen(false);
    if (menu === 'more') setMoreOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`navbar ${isSticky ? 'is-sticky' : ''}`}>
      <Link to="/"><img src={logo} alt="Logo" className='logo' /></Link>

      {/* Hamburger Menu Icon for Mobile View */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        {isMenuOpen ? <IoMdClose size={30}  className='close'/> : <RxHamburgerMenu size={30}  className='open'/>}
      </div>

      <ul className={isMenuOpen ? 'open' : ''}>
        <li><Link to="/">Home</Link></li>
        <li 
          onMouseEnter={() => handleMouseEnter('products')} 
          onMouseLeave={() => handleMouseLeave('products')}
        ><Link to="/products">Products</Link>
        {productsOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/clinical_data">Clinical Data</Link></li>
                <li><Link to="/fault_detection">Fault Detection</Link></li>
                <li><Link to="/agri_sens">Agrisens</Link></li>
              </ul>
            </div>
          )}
        </li>
        {/* <li><Link to="/applications">Applications</Link></li> */}
        <li 
          onMouseEnter={() => handleMouseEnter('services')} 
          onMouseLeave={() => handleMouseLeave('services')}
        >
          <Link to="/services">Services</Link>
          {servicesOpen && (
            <div className="services-menu">
              <div className='services-content'>
                  <div className='services-row'>
                    <header>Product Engineering</header>
                    <ul className='services-list'>
                      <li><Link to="/website_development">Website Development</Link></li>
                      <li><Link to="/software_development">Software Development</Link></li>
                      <li><Link to="/hardware_development">Hardware Development</Link></li>
                    </ul>  
                  </div>
                  <div className='services-row'>
                    <header>Industry</header>
                    <ul className='services-list'>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                      <li><Link to="/industries">Industries</Link></li>
                      <li><Link to="/logistics">Logistics</Link></li>
                      <li><Link to="/agriculture">Agriculture</Link></li>
                    </ul>  
                  </div>
                  {/* <div className='services-row'>
                    <header>Our Features</header>
                    <ul className='services-list'>
                      <li><Link to="/service1">Performance Monitoring</Link></li>
                      <li><Link to="/service2">Device Debugging</Link></li>
                      <li><Link to="/service3">Product Analytics</Link></li>
                      <li><Link to="/service3">Solutions</Link></li>
                      <li><Link to="/service3">New Releases</Link></li>
                    </ul>  
                  </div> */}
              </div>
              
            </div>
          )}
        </li>
        <li 
          onMouseEnter={() => handleMouseEnter('more')} 
          onMouseLeave={() => handleMouseLeave('more')}
        ><Link>More</Link>
        {moreOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
              </ul>
            </div>
          )}
        </li>

        <li><Link to="/account">Account</Link></li>
      </ul>

      {/* <div className='buttons'>
        <button className='btn'>Get Started</button>
      </div> */}
    </div>
  );
}

export default Navbar;
