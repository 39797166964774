import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/contact/', {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });
      if (response.status === 200) {
        setResponseMessage('Message sent successfully!');
        setMessageType('success');
        // Clear the form data if needed
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        });
      }
    } catch (error) {
      console.error('There was an error sending the message!', error);
      setResponseMessage('Failed to send message.');
      setMessageType('error');
    }
  };

  return (
    <div className="contact-form">
      {/* Office Info with image */}
      <div className="first-container">
        <div className="info-container">
          <div>
            <h3>Address</h3>
            <p>16-11-411/1, Moosarambagh Hyderabad, India 500036</p>
          </div>
          <div>
            <h3>Let's Talk</h3>
            <p>+91-9030620999</p>
          </div>
          <div>
            <h3>General Support</h3>
            <p>info@embed-view.com</p>
          </div>
        </div>
      </div>
      {/* Contact form with input fields */}
      <div className="second-container">
        <h2>Get In Touch</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name-input">Tell us your name*</label>
            <input id="name-input" type="text" name="firstName" placeholder="First name" value={formData.firstName} required onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Last name" value={formData.lastName} required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email-input">Enter your email*</label>
            <input id="email-input" type="email" name="email" placeholder="Eg. example@email.com" value={formData.email} required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone-input">Enter phone number*</label>
            <input id="phone-input" type="tel" name="phone" placeholder="Eg. +1 800 000000" value={formData.phone} required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="message-textarea">Message</label>
            <textarea id="message-textarea" name="message" placeholder="Write us a message" value={formData.message} required onChange={handleChange}></textarea>
          </div>
          <button type="submit">Send message</button>
        </form>
        {responseMessage && (
          <div className={`response-message ${messageType}`}>
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
