import React from 'react';
import { Link } from 'react-router-dom';
import './Careers.css';

const Careers = () => {
    return (
        // careers page
        <>
            {/* first box */}
            <div className="box">
                {/* left column */}
                <div className="box-col box-80">
                    <h4>Embedded Software Engineer </h4>
                    {/* function */}
                    <span className="function"> firmware engineer</span>
                </div>
                {/* right column */}
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/embedded_software">Apply</Link>
                </div>
            </div>
    
            <div className="box">
                <div className="box-col box-80">
                    <h4>Full Stack Java Developer</h4>
                    <span className="function">user interface design, back-end programming, database management, and testing</span>
                </div>
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/full_stack_java_developer">Apply</Link>
                </div>
            </div>
            <div className="box">
                <div className="box-col box-80">
                    <h4>Full Stack Python Developer</h4>
                    <span className="function">handling the front- and back-end of a website or an application</span>
                </div>
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/full_stack_python_developer">Apply</Link>
                </div>
            </div>
            <div className="box">
                <div className="box-col box-80">
                    <h4>Database Developer</h4>
                    <span className="function">database designers or database programmers</span>
                </div>
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/database_developer">Apply</Link>
                </div>
            </div>
            <div className="box">
                <div className="box-col box-80">
                    <h4>Front Officer Receptionist</h4>
                    <span className="function">front desk clerk</span>
                </div>
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/front_officer_receptionist">Apply</Link>
                </div>
            </div>
            <div className="box">
                <div className="box-col box-80">
                    <h4>PCB Designer and Engineer</h4>
                    <span className="function"> enable a simple action in an electric device</span>
                </div>
                <div className="box-col box-20 text-right">
                    <Link className="button-apply" to="/pcb_design">Apply</Link>
                </div>
            </div>
        </>
      );
}

export default Careers

