import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import mainvideo from '../../Assets/home_page_main_video.mp4';

function Home() {
  return (
    <>
    {/* In home page image with container */}
      <div className="main_container">
      <video src={mainvideo} autoPlay loop muted className="background_video"></video>
        <h1>Innovative Solutions</h1>
        <h2>For Your Business</h2>
        <p>Use Data to get a 360-Degree</p>
        <p>View of Your Business</p>
        <Link className="learn_more_button" to ="/learn_more">Let's Go!</Link>
      </div>
      {/* Cards with data */}
      <div>
      <section className="sub_container">
      <div className="card_data">
        <div className="content_data">
          <h6>Sensor-Data Collection</h6>
          <div className="hover_content">
            <p>
            Our hardware solutions are designed to collect and analyze sensor data in real-time, giving you valuable insights into your business operations.
            </p>
          </div>
        </div>
      </div>
      <div className="card_data">
        <div className="content_data">
          <h6>Cloud-Based Storage</h6>
          <div className="hover_content">
            <p>
            Our cloud-based storage solutions provide secure, reliable, and scalable data storage for your business.
            </p>
          </div>
        </div>
      </div>
      <div className="card_data">
        <div className="content_data">
          <h6>Visualization</h6>
          <div className="hover_content">
            <p>
            Our visualization tools allow you to turn data into actionable insights, giving you a competitive edge in your industry.
            </p>
          </div>
        </div>
      </div>
      <div className="card_data">
        <div className="content_data">
          <h6>Tailored Solutions</h6>
          <div className="hover_content">
            <p>
            At Embed-View, we understand that every business is unique. That's why we provide tailored solutions that meet your specific needs, ensuring you get the most out of our services.
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* cards right side text */}
    <div className='sub_container2'>
      <h1>Let Us Take Your</h1>
      <h1>Business To The Next</h1>
      <h1>Level</h1>
    </div>
    </div>
    {/* image with data */}
    <div className="sub_container3">
      <div className="sub_first-container">
        <div className="info-container">
          <h3>
          Innovative Solutions for Your Business
          </h3>

        </div>
      </div>
      <div className="second-container">
          <p>
          At Embed-View, we specialize in developing and delivering innovative hardware and software solutions that help you make sense of your data. Our expertise in sensor data collection, cloud-based storage, and visualization ensures you get the insights you need to succeed. Whether you're looking to modernize your analytics, accelerate your data science, or improve your customer experience, we have the solutions to help you achieve your goals.</p>
          <Link className='more_details_button' to={"/about"}>Learn More</Link>
      </div>
    </div>
    </>
  );
}

export default Home;
