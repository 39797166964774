import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Chat = () => {
  const phoneNumber = '919030620999'; // Replace with your WhatsApp phone number

  return (
    <div>
      {/* Only use the Floating WhatsApp Widget */}
      <FloatingWhatsApp
        phoneNumber={phoneNumber}
        accountName="Embed View"
        avatar="https://cdn-icons-png.flaticon.com/512/149/149071.png"
        statusMessage="Typically replies within a few minutes"
        chatMessage="Hi there! How can we help you?"
        allowClickAway
        notification
        notificationSound
        placeholder="Type your message here..."
      />
    </div>
  );
};

export default Chat;
