import React from 'react';
import './HardwareDevelopment.css';
import sensorsprocess from '../../Assets/sensors_processes.gif';

const HardwareDevelopment = () => {
  return (
    // hardware development content
    <div className='hardware_content'>
        <h5>Hardware Development</h5>
        {/* image hardware development */}
        <div className='hardware_img'>
            <img src={sensorsprocess} alt="sensors_processes" />
        </div>
        {/* text hardware development co2 */}
        <div className='hardware_text1'>
            <p><h4>Carbon Dioxide:</h4>A CO2 sensor, or carbon dioxide sensor, is a device that detects and measures the concentration of carbon dioxide gas in the air.</p>
        </div>    
        {/* applications list co2 */}
        <div className='hardware_applications1'>
        <h4>Applications of CO2 Sensors:</h4>
                <div className='hardware_data'>
                    <p>CO2 sensors have a wide range of applications across different industries due to their ability to detect and measure CO2 concentration accurately. 
                        Here are some common applications:</p>
                </div>
                {/* list co2 */}
                <div className='hardware_list1'>
                    <p><h3>Industrial Applications:</h3>Used in safety monitoring in industries such as food and beverage, mining, and chemical manufacturing, where 
                    CO2 is used or produced. It helps detect leaks and ensure worker safety.</p>
                    <p><h3>Medical Applications:</h3>Employed in medical devices like capnography, where they measure CO2 concentration in a patient's exhaled air. 
                    This helps assess respiratory function during surgeries and in critical care.</p>
                    <p><h3>Automotive Industry:</h3>Used in vehicle climate control systems to maintain comfortable cabin air quality by monitoring CO2 levels. 
                    Some electric vehicles also use CO2 sensors for cabin air quality management.</p>
                    <p><h3>Environmental Monitoring:</h3>Used in environmental monitoring stations to measure CO2 levels in the atmosphere, which is critical for 
                    studying climate change and air pollution.</p>
                    <p><h3>Smart Homes and IoT Devices:</h3>Integrated into smart home devices and IoT systems for real-time air quality monitoring, enabling 
                    automatic adjustments to ventilation or air purification systems.</p>
                </div>
        </div>
        {/* text hardware development oxygen */}
        <div className='hardware_text2'>
            <p><h4>Oxygen:</h4>Oxygen (O₂) sensors are devices used to measure the concentration of oxygen in a gas mixture, most commonly in automotive and industrial applications.</p>
        </div>    
        {/* applications list oxygen */}
        <div className='hardware_applications2'>
        <h4>Applications of CO2 Sensors:</h4>
                <div className='hardware_list2'>
                    <p><h3>Automotive Industry:</h3>O₂ sensors are a critical component in a vehicle's emission control system. They help the ECU maintain the optimal 
                    air-fuel mixture for efficient combustion, minimizing harmful emissions such as CO, NOx, and hydrocarbons.</p>
                    <p><h3>Industrial Applications:</h3>O₂ sensors are used in industrial furnaces, boilers, and kilns to monitor and control the combustion process, 
                    ensuring efficient fuel usage and reducing emissions.</p>
                    <p><h3>Medical Applications:</h3>O₂ sensors are used in medical devices like ventilators and anesthesia machines to measure and control oxygen 
                    levels in the air delivered to patients.</p>
                    <p><h3>Gas Detection:</h3>O₂ sensors are used in confined spaces or hazardous environments (like mines or submarines) to detect oxygen deficiency, 
                    which can be life-threatening.</p>
                    <p><h3>Fire Detection:</h3>In certain fire detection systems, O₂ sensors help detect a reduction in oxygen levels, which could indicate the 
                    presence of a fire.</p>
                </div>
        </div>
        {/* text hardware development co2 */}
        <div className='hardware_text3'>
            <p><h4>Indoor Air Quality:</h4>An Indoor Air Quality (IAQ) sensor is a device used to measure the quality of air within a building or enclosed space. These sensors help 
            monitor various environmental parameters that affect indoor air quality, such as temperature, humidity, carbon dioxide (CO₂), volatile organic compounds 
            (VOCs), particulate matter (PM), and other gases like carbon monoxide (CO).</p>
        </div>    
        {/* applications list co2 */}
        <div className='hardware_applications3'>
        <h4>Applications of CO2 Sensors:</h4>
                <div className='hardware_list3'>
                    <p><h3>Industrial Facilities:</h3>Ensuring worker safety by monitoring air quality in factories, warehouses, and workshops where exposure to 
                    chemicals, dust, or toxic gases may occur.</p>
                    <p><h3>Healthcare Settings:</h3>Monitoring air quality in hospitals, clinics, and laboratories to prevent the spread of airborne pathogens, 
                    reduce infection risks, and ensure a sterile environment.</p>
                    <p><h3>Transportation:</h3>Ensuring good air quality in public transport systems, including buses, trains, and aircraft, where people spend 
                    considerable time in enclosed spaces.</p>
                    <p><h3>Hospitality Industry:</h3>Used in hotels, restaurants, and event venues to maintain comfort and safety standards for guests by 
                    ensuring clean and healthy indoor air.</p>
                    <p><h3>Agricultural Applications:</h3>Monitoring greenhouse environments to control CO₂ levels, temperature, and humidity, optimizing 
                    plant growth conditions.</p>
                </div>
        </div>
        {/* text hardware development co */}
        <div className='hardware_text4'>
            <p><h4>Carbon Monoxide:</h4>A Carbon Monoxide (CO) sensor is a device used to detect the presence of carbon monoxide gas in the environment. Carbon monoxide is a colorless, 
            odorless, and tasteless gas that can be extremely dangerous to humans and animals, even in small concentrations. CO sensors are crucial for safety in various 
            settings, such as homes, workplaces, vehicles, and industrial environments.</p>
        </div>    
        {/* applications list co */}
        <div className='hardware_applications4'>
        <h4>Applications of CO2 Sensors:</h4>
                <div className='hardware_list4'>
                    <p><h3>Automotive Industry:</h3>Used in vehicles to monitor exhaust emissions and ensure compliance with environmental regulations. They are 
                    also used in garages and tunnels to detect CO build-up and activate ventilation systems.</p>
                    <p><h3>Industrial Safety:</h3>Used in industries like manufacturing, mining, and power plants to detect CO leaks and prevent poisoning. 
                    They help protect workers in environments where CO exposure is a risk.</p>
                    <p><h3>Portable CO Detectors:</h3>Used by firefighters, first responders, and industrial workers to detect CO in hazardous environments. 
                    These portable devices are designed for personal safety and are often worn or carried.</p>
                    <p><h3>Medical Applications:</h3>Used in medical facilities to monitor CO exposure in patients, particularly in cases of suspected 
                    poisoning or for monitoring smoking cessation programs.</p>
                    <p><h3>Environmental Monitoring:</h3>Used for monitoring CO levels in outdoor environments to study air quality and pollution, especially in urban areas.</p>
                </div>
        </div>
        {/* text hardware development pm */}
        <div className='hardware_text5'>
            <p><h4>Particle Matter:</h4>Particle matter (PM) refers to tiny particles suspended in the air. These particles can vary in size, and they are usually categorized 
            based on their aerodynamic diameter:</p>
        </div>    
        
        <div className='hardware_data_content'>
                    <p><b>PM10.0:</b>Particles with a diameter of 10 micrograms or less.</p>
                    <p><b>PM4.0:</b>Particles with a diameter of 4 micrograms or less.</p>
                    <p><b>PM2.5:</b>Fine particles with a diameter of 2.5 micrograms or less.</p>
                    <p><b>PM1.0:</b>Ultrafine particles with a diameter of 1 microgram or less.</p>
                </div>
        <div className='hardware_applications5'>
        <h4>Applications of CO2 Sensors:</h4>
        {/* applications list pm */}
            <div className='hardware_list5'>
                <p><h3>Air Quality Monitoring:</h3>Used by environmental agencies and research institutions to monitor outdoor air quality.</p>
                <p><h3>Industrial Applications:</h3>Monitors air quality in factories, mines, construction sites, and other industrial settings to protect workers' 
                health and ensure compliance with regulations.</p>
                <p><h3>Healthcare Applications:</h3>Used in hospitals and clinics to maintain a clean environment, particularly in sensitive areas like operating 
                rooms and intensive care units.</p>
                <p><h3>Agriculture:</h3>Helps in monitoring dust and particulate levels in agricultural settings, such as grain processing or livestock areas, 
                to ensure the safety of workers and animals.</p>
                <p><h3>Environmental Research:</h3>Used in research studies to understand the impact of particulate matter on human health, vegetation, and climate.</p>
            </div>
        </div>
        {/* text hardware development temp */}
        <div className='hardware_text6'>
            <p><h4>Temperature and Humidity:</h4>A temperature and humidity sensor measures the temperature and moisture levels in the air. These sensors are widely used in various applications for monitoring environmental conditions.</p>
        </div>    
        <div className='hardware_applications6'>
        <h4>Applications of CO2 Sensors:</h4>
        {/* applications list temp */}
                <div className='hardware_list6'>
                    <p><h3>Weather Stations:</h3>Monitors environmental conditions for weather forecasting.</p>
                    <p><h3>HVAC Systems:</h3>Helps maintain optimal temperature and humidity in homes and buildings.</p>
                    <p><h3>Agriculture:</h3>Used in greenhouses and crop management to ensure suitable environmental conditions for plant growth.</p>
                    <p><h3>Industrial Automation:</h3>Monitors machinery and industrial environments to avoid overheating or excess moisture, which can affect performance and safety.</p>
                    <p><h3>IoT Devices:</h3>Utilised in smart homes for automated environmental monitoring and control.</p>
                </div>
        </div>
    </div>
  )
}

export default HardwareDevelopment

