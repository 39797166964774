import React from 'react';
import './Industries.css';
import industrygraphics from '../../Assets/industry-graphics.gif';
import industryplanning from '../../Assets/industry-planning.gif';
import industryprocess from '../../Assets/industry-process.gif';
import industryprogress from '../../Assets/industry_progress.gif';

const Industries = () => {
  return (
    // Industries services page content
    <div className='industry_content'>
        <h5>Industries</h5>
        <div className='industry_para_content'>
            <img className='img1' src={industryplanning} alt="industry-planning" />
            <p><b>Industrial Robots:</b> Used in manufacturing for tasks like assembly, welding, and material handling, increasing productivity and precision.</p>
            <p><b>Edge Computing Devices:</b> Hardware that processes data locally, reducing latency and enabling real-time decision-making in industrial automation.</p>
            <p><b>Enterprise Resource Planning (ERP) Systems:</b> Integrate various business processes such as inventory management, human resources, and supply chain operations into a single platform.</p>
            <p><b>Predictive Maintenance Software:</b> Analyzes data from sensors to predict equipment failures, reducing downtime and repair costs.</p>
            <p><b>Smart Manufacturing: </b>IoT-enabled machines and sensors track production metrics, monitor equipment health, and optimize operations.</p>
        </div>
        {/* Benefits content with image */}
        <div className='industry_benefits'>
            <h5>Benefits of Industries:</h5>
            <div className='benefits'>
                <p>Enhanced operational efficiency through automation and reduced manual labor.</p>
                <p>Improved safety by detecting hazardous conditions and automating dangerous tasks.</p>
                <p>Streamlined workflows and optimized resource allocation.</p>
                <p>Cost savings by reducing waste, improving quality, and minimizing downtime.</p>
                <p>Reduced operational costs through automation and efficient resource utilization.</p> 
            </div>
            <img className='img2' src={industrygraphics} alt="industry-graphics" />
        </div>
        {/* Applications content with image */}
        <div className='industry_applications'>
            <h5>Applications of Industries:</h5>
            <div className='applications'>
                <p>Automate repetitive tasks, boosting productivity and precision in manufacturing processes.</p>
                <p>Integrate business functions, streamlining operations and improving resource management across industries.</p>
                <p>Uses data analytics to forecast equipment failures, minimizing downtime and maintenance costs.</p>
                <p>Monitor equipment and environmental conditions, enabling real-time adjustments and improving safety.</p>
                <p> Tracks shipments and optimizes routes, enhancing delivery efficiency and reducing transportation costs.</p> 
            </div>
            <img className='img3' src={industryprogress} alt="industry-progress" />
        </div>
        {/* Industry down image */}
        <div className='industry_down_image'>
            <img className='img4' src={industryprocess} alt="industry-process" />
        </div>
    </div>
  )
}

export default Industries
