import React, { useState } from 'react';
import './EmbeddedSoftwareEngineer.css';

const EmbeddedSoftwareEngineer = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/embedded_software/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // embedded software engineer page
<div className="job-description-container">
      {/* embedded software engineer description */}
      <div className="job-details">
        <h2>Embedded Software Engineer</h2>
        {/* embedded software engineer responsibilities description */}
        <h3>Responsibilities</h3>
        <div className='job-description'>
          <p>Hardware: Schematic design, PCB design (basic knowledge), Bill of Material and component selection, Use of H/w and S/w debuggers, Testing and integration.</p>
          <p>Strong C programming knowledge.</p>
          <p>Interfaces & Protocols: RS232, RS485, CAN, I2C, SPI, UART, Modbus, BLE, Wi-Fi, LTE, Bootloader, Ethernet.</p>
          <p>Experience with one or more RTOS-Free RTOS, Zephyr preferred.</p>
          <p>Proficiency with electronics bench test equipment such as oscilloscopes, logic analyzers, etc.</p>
          <p>Experience developing low-level device drivers for communication protocols such as CAN (-FD), UART, RS232, RS485, Ethernet, TCP/IP, USB, SPI, and I2C.</p>
          <p>Autocar knowledge is an advantage.</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* embedded software engineer education skills description */}
        <div className='job-description'>
          <p>Bachelor's degree in Electrical Engineering, Computer Science, or a related field. An advanced degree (Master's or higher) is a plus.</p>
          <p>Minimum of one year of hands-on experience in embedded software development, demonstrating a strong understanding of the software development lifecycle.</p>
          <p>Programming Languages C and C++ programming languages for embedded systems development.</p>
          <p>Real-Time Operating Systems (RTOS) Practical experience working with Real-Time Operating Systems and developing software for time-critical applications.</p>
          <p>Hardware-Software Integration Hands-on experience collaborating with hardware engineers to integrate software components seamlessly into embedded systems.</p>
          <p>Proficient in using version control systems (e.g., Git) for source code management and collaborative development.</p>
          <p>Adequate knowledge of reading schematics and data sheets for components with the ability to create clear and concise technical documents for design, implementation, and testing.</p>
          <p>Excellent verbal and written communication skills, with the ability to collaborate effectively within cross-functional teams.</p>
          <p>Problem-solving skills and the ability to troubleshoot complex issues in embedded software systems.</p>
          <p>A proactive learner with the ability to adapt to new technologies and methodologies in the dynamic field of embedded systems.</p>
          <p>A collaborative and team-oriented mindset, with the ability to work harmoniously within a diverse and multidisciplinary team environment.</p>
        </div>
        {/* embedded software engineer additional description */}
        <div className='job-description-info'>
            <p><b>Job Category:</b>Embedded Software Engineer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Musrambagh</p>
        </div>
      </div>
      {/* embedded software engineer apply form */}
      <div className="apply-form">
        {/* embedded software engineer apply form heading */}
        <h3>Apply for this position</h3>
        {/* embedded software engineer apply form box */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name" required />
          
          <label>Email *</label>
          <input type="email" name="email" required />
          
          <label>Phone *</label>
          <input type="tel" name="phone" required />
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4" required></textarea>

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required/>
          
          <button type="submit">Submit</button>
        </form>
        {/* Display response message */}
        {responseMessage && (
          <div className="response-message">
            <p>{responseMessage}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmbeddedSoftwareEngineer
