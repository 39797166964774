import React, { useState } from 'react';
import './DatabaseDeveloper.css';


const DatabaseDeveloper = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/database_developer/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // database developer page
    <div className="database-description-container">
      {/* database description */}
      <div className="database-details">
        <h2>Database Developer</h2>
        <h3>Responsibilities</h3>
        {/* database responsibilities description */}
        <div className='database-description'>
          <p>Collaborate with the database development team to understand business requirements and contribute to database design.</p>
          <p>Assist in the development and implementation of efficient and secure database solutions and in integration with cloud technologies.</p>
          <p>Conduct database testing, troubleshooting, and performance tuning to ensure optimal functionality.</p>
          <p>Collaborate with cross-functional teams to integrate database solutions into broader software applications.</p>
          <p>Support the team with general administrative tasks as needed.</p>
          <p>Stay updated on industry trends and best practices in database development..</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* database education skills description */}
        <div className='database-description'>
          <p>Completed or Pursuing a final year degree in Computer Science, Information Technology, or a related field.</p>
          <p>Strong understanding of relational database management systems (RDBMS) and SQL.</p>
          <p>Familiarity with database design principles and Normalisation.</p>
          <p>Proficiency in one or more programming languages (e.g., Python, Java, C#).</p>
          <p>Knowledge of any Cloud Data Services an advantage.</p>
          <p>Experience with version control systems, such as Git, is a plus.</p>
          <p>Strong analytical and problem-solving skills..</p>
          <p>Excellent communication and collaboration skills.</p>
        </div>
        {/* database additional description */}
        <div className='database-description-info'>
            <p><b>Job Category:</b>Database Developer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Musrambagh</p>
        </div>
      </div>
      {/* database apply form */}
      <div className="database-apply-form">
        {/* database apply form heading */}
        <h3>Apply for this position</h3>
        {/* database apply form box */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name" required />
          
          <label>Email *</label>
          <input type="email" name="email" required />
          
          <label>Phone *</label>
          <input type="tel" name="phone" required />
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4" required></textarea>

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required />
          
          <button type="submit">Submit</button>
        </form>
        {/* Display response message */}
        {responseMessage && (
          <div className="response-message">
            <p>{responseMessage}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default DatabaseDeveloper
