import React, { useState } from 'react';
import './PCBDesign.css';


const PCBDesign = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/pcb_design/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // PCB page
<div className="pcb-description-container">
      {/* PCB description */}
      <div className="pcb-details">
        <h2>PCB Design and Engineer</h2>
        {/* PCB responsibilities description */}
        <h3>Responsibilities</h3>
        <div className='pcb-description'>
          <p>Design and develop PCB layouts and schematics using industry-standard design software.</p>
          <p>Select and source appropriate components for PCB designs.</p>
          <p>Analyze signal integrity and power integrity to ensure reliable performance.</p>
          <p>Optimize designs for manufacturability and testability, including DFM (Design for Manufacturability), DRC (Design Rule Check) and DFT (Design for Test) considerations.</p>
          <p>Collaborate with cross-functional teams, including hardware, firmware, and mechanical engineers, to integrate PCBs into electronic devices.</p>
          <p>Conduct design reviews and provide feedback to ensure compliance with project specifications and industry standards.</p>
          <p>Document design processes, specifications, and test plans.</p>
          <p>Stay informed about emerging PCB technologies, industry standards, and best practices to continually improve design efficiency and effectiveness.</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* pcb education skills description */}
        <div className='pcb-description'>
          <p>Basic understanding of PCB design principles and electronic components.</p>
          <p>Footprint and symbol creation for components in PCB design.</p>
          <p>Familiarity with PCB design software such as Altium Designer, Eagle, or KiCad.</p>
          <p>Knowledge of signal integrity and power integrity principles.</p>
          <p>Understanding of manufacturing processes and design constraints for PCBs.</p>
          <p>Strong analytical and problem-solving skills.</p>
          <p>Ability to work independently and as part of a multidisciplinary team.</p>
          <p>Knowledge of standards and regulations related to PCB design and electronics, such as IPC standards.</p>
          <p>Experience with version control systems, such as Git, is a plus.</p>
        </div>
        {/* pcb additional description */}
        <div className='pcb-description-info'>
            <p><b>Job Category:</b>PCB Design and Engineer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Musrambagh</p>
        </div>
      </div>
      {/* pcb apply form */}
      <div className="pcb-apply-form">
        {/* pcb apply form heading */}
        <h3>Apply for this position</h3>
        {/* pcb apply form box */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name" required />
          
          <label>Email *</label>
          <input type="email" name="email" required />
          
          <label>Phone *</label>
          <input type="tel" name="phone" required />
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4" required></textarea>

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required />
          
          <button type="submit">Submit</button>
        </form>
          {/* Display response message */}
          {responseMessage && (
              <div className="response-message">
                  <p>{responseMessage}</p>
              </div>
              )}
      </div>
    </div>
  )
}

export default PCBDesign
