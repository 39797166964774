import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Products from './Components/Products/Products';
import Applications from './Components/Applications/Applications';
import Services from './Components/Services/Services';
import AboutUs from './Components/AboutUs/AboutUs';
import ContactForm from './Components/ContactForm/ContactForm';
import Account from './Components/Account/Account';
import Footer from './Components/Footer/Footer';
import Chat from './Components/Chat/Chat';
import WebsiteDevelopment from './Components/Services/WebsiteDevelopment';
import SoftwareDevelopment from './Components/Services/SoftwareDevelopment';
import HardwareDevelopment from './Components/Services/HardwareDevelopment';
import Healthcare from './Components/Services/Healthcare';
import Industries from './Components/Services/Industries';
import Logistics from './Components/Services/Logistics';
import Agriculture from './Components/Services/Agriculture';
import Careers from './Components/Careers/Careers';
import EmbeddedSoftwareEngineer from './Components/Careers/EmbeddedSoftwareEngineer';
import FullStackJavaDeveloper from './Components/Careers/FullStackJavaDeveloper';
import FullStackPythonDeveloper from './Components/Careers/FullStackPythonDeveloper';
import DatabaseDeveloper from './Components/Careers/DatabaseDeveloper';
import FrontOfficerReceptionist from './Components/Careers/FrontOfficerReceptionist';
import LearnMore from './Components/Home/LearnMore';
import PCBDesign from './Components/Careers/PCBDesign';
import ClinicalData from './Components/Products/ClinicalData';
// import Login from './Components/Account/Login';
import Profile from './Components/Account/Profile';
import ProfileForGoogleLogin from './Components/Account/ProfileForGoogleLogin';
import AgriSens from './Components/Products/AgriSens';
import TermsAndConditions from './Components/Footer/TermsAndConditions';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy';
import FaultDetection from './Components/Products/FaultDetection';





const App = () => {
  return (
    <Router>
      <div className='container'>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/website_development" element={<WebsiteDevelopment />} />
          <Route path="/software_development" element={<SoftwareDevelopment />} />
          <Route path="/hardware_development" element={<HardwareDevelopment />} />
          <Route path="/healthcare" element={<Healthcare />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/agriculture" element={<Agriculture />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/embedded_software" element={<EmbeddedSoftwareEngineer />} />
          <Route path="/full_stack_java_developer" element={<FullStackJavaDeveloper />} />
          <Route path="/full_stack_python_developer" element={<FullStackPythonDeveloper />} />
          <Route path="/database_developer" element={<DatabaseDeveloper />} />
          <Route path="/front_officer_receptionist" element={<FrontOfficerReceptionist />} />
          <Route path="/pcb_design" element={<PCBDesign />} />
          <Route path="/learn_more" element={<LearnMore />} />
          <Route path="/clinical_data" element={<ClinicalData />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile_google" element={<ProfileForGoogleLogin />} />
          <Route path="/account" element={<Account />} />
          <Route path="/agri_sens" element={<AgriSens />} />
          <Route path="/fault_detection" element={<FaultDetection />} />
          <Route path="/terms_conditions" element={<TermsAndConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <Chat />
      </div>
    </Router>
  );
};


export default App;
