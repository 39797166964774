import React, { useState } from 'react';
import './FrontOfficerReceptionist.css';

const FrontOfficerReceptionist = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/front_officer_receptionist/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // front officer receptionist page
    <div className="receptionist-description-container">
        {/* front officer receptionist description */}
        <div className="receptionist-details">
            <h2>Front Officer Receptionist</h2>
            <h3>Responsibilities</h3>
            {/* front officer receptionist responsibilities description */}
            <div className='receptionist-description'>
                <p><b>Greeting and Assisting Visitors:</b> Warmly welcome guests, provide them with necessary information, and direct them to their destinations or appropriate staff members.</p>
                <p><b>Managing Phone Calls:</b> Handle incoming calls, take messages, and route calls to the relevant departments or individuals.</p>
                <p><b>Scheduling Appointments:</b> Coordinate and manage appointment calendars for staff members, and book meeting rooms as needed.</p>
                <p><b>Handling Administrative Tasks:</b> Perform clerical duties such as filing, data entry, and handling mail to support office operations.</p>
                <p><b>Maintaining Reception Area:</b> Ensure the reception area is tidy, organized, and equipped with necessary supplies, creating a professional and welcoming environment.</p>
            </div>
            <h3>Required Education Skills</h3>
            {/* front officer receptionist education skills description */}
            <div className='receptionist-description'>
                <p><b>Educational Background: </b>High school diploma or equivalent; additional certifications in office management or customer service are advantageous.</p>
                <p><b>Communication Skills:</b> Excellent verbal and written communication skills to interact effectively with clients and team members.</p>
                <p><b>Technical Proficiency:</b> Basic knowledge of office software (e.g., Microsoft Office) and familiarity with common office equipment (e.g., printers, scanners).</p>
                <p><b>Organizational Skills:</b> Strong organizational abilities to manage multiple tasks and maintain a well-ordered front office.</p>
                <p><b>Customer Service: </b>Exceptional customer service skills to handle inquiries, resolve issues, and provide a positive experience for visitors.</p>
            </div>
            {/* front officer receptionist additional description */}
            <div className='receptionist-description-info'>
                <p><b>Job Category:</b>Front Officer Receptionist</p>
                <p><b>Job Type:</b>Full Time</p>
                <p><b>Job Location:</b>Musrambagh</p>
            </div>
        </div>
        {/* front officer receptionist apply form */}
        <div className="receptionist-apply-form">
            <h3>Apply for this position</h3>
            {/*front officer receptionist apply form box */}
            <form onSubmit={handleSubmit}>
                <label>Full Name *</label>
                <input type="text" name="full_name" required />
                
                <label>Email *</label>
                <input type="email" name="email" required />
                
                <label>Phone *</label>
                <input type="tel" name="phone" required />
                
                <label>Cover Letter *</label>
                <textarea name="cover_letter" rows="4" required></textarea>

                <label htmlFor="resume">Resume:</label>
                <input type="file" id="resume" name="resume" required />
                
                <button type="submit">Submit</button>
            </form>
            {/* Display response message */}
            {responseMessage && (
            <div className="response-message">
                <p>{responseMessage}</p>
            </div>
            )}
        </div>
    </div>
  )
}

export default FrontOfficerReceptionist
