import React from 'react';
import './Footer.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

const Footer = () => {
  return (
    // footer section 
    <div className='footer'>
        {/* footer links section */}
        <div className='sb__footer section__padding'>
            {/* footer links container */}
            <div className='sb__footer-links'>
                {/* footer links div */}
                {/* <div className='sb__footer-links_div'>
                    <h4>For Business</h4>
                    <a href="/employer">
                       <p>Employers</p>
                    </a>
                    <a href="/healthplan">
                       <p>Health Plan</p>
                    </a>
                    <a href="/individual">
                       <p>Individual</p>
                    </a>
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Resources</h4>
                    <a href="/resources">
                       <p>Resource Center</p>
                    </a>
                    <a href="/resources">
                       <p>Resources1</p>
                    </a>
                    <a href="/resources">
                       <p>Resources2</p>
                    </a>
                </div> */}
                {/* <div className='sb__footer-links_div'>
                    <h4>Patners</h4>
                    <a href="/resources">
                       <p>Employee1</p>
                    </a>
                    <a href="/resources">
                       <p>Employee2</p>
                    </a>
                    <a href="/resources">
                       <p>Employee3</p>
                    </a>
                </div> */}
                <div className='sb__footer-links_div'>
                    <h4>Company</h4>
                    <a href="/about">
                       <p>About Us</p>
                    </a>
                    <a href="/contact">
                       <p>Contact Us</p>
                    </a>
                    <a href="/services">
                       <p>Services</p>
                    </a>
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Follow Us:</h4>
                    <div className='socialmedia'>
                        <p className='icon'><FaFacebookF /></p>
                        <p className='icon'><FaInstagramSquare /></p>
                        <p className='icon'><FaTwitter /></p>
                        <p className='icon'><FaGithub /></p>
                    </div>
                </div>
            </div>
            <hr />
            {/* footer below */}
            <div className='sb__footer-below'>
                {/* footer below container */}
                <div className='sb__footer-copyright'>
                    <p>
                        @{new Date().getFullYear()} Embed View. All rights reserved.
                    </p>
                </div>
                {/* footer below links */}
                <div className='sb__footer-below-links'>
                    <a href="/terms_conditions"><div><p>Terms & Conditions</p></div></a>
                    <a href="/privacy_policy"><div><p>Privacy</p></div></a>
                    {/* <a href="/security"><div><p>Security</p></div></a> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
