import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import './Services.css';

const ServiceCard = ({ img, title, subtitle, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    // Create a card for each service only hover effective
    <figure
      className={`snip1584 ${isHovered ? 'hover' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={img} alt={title} />
      <figcaption>
        <h3>{title}</h3>
        <h5>{subtitle}</h5>
      </figcaption>
      <Link to={link} className="learn-more-link"></Link>
    </figure>
  );
};

export default ServiceCard;
