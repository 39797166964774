import React, { useState } from 'react';
import './FullStackPythonDeveloper.css';

const FullStackPythonDeveloper = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    
    // Client-side validation
    const email = formData.get('email');
    const phone = formData.get('phone');

    if (!validateEmail(email)) {
      setResponseMessage('Invalid email format.');
      return;
    }

    if (!validatePhone(phone)) {
      setResponseMessage('Invalid phone number format.');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/full_stack_python_developer/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'Application submitted successfully');
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
        setResponseMessage('Error: ' + JSON.stringify(errorData));
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
    }
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone number validation regex (adjust as needed)
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  return (
    // full stack python developer page
    <div className="python-description-container">
      {/* full stack python developer description */}
      <div className="python-details">
        <h2>Full Stack Python Developer</h2>
        {/* full stack python developer responsibilities */}
        <h3>Responsibilities</h3>
        <div className='python-description'>
          <p><b>Design and Develop Applications:</b> Create scalable and efficient web applications using Python frameworks like Django or Flask, and ensure smooth integration between front-end and back-end components.</p>
          <p><b>Database Management:</b> Design, implement, and maintain databases using SQL or NoSQL technologies. Write efficient queries and manage data storage, security, and performance.</p>
          <p><b>API Development:</b> Develop and document RESTful APIs to enable seamless communication between the server and client-side applications.</p>
          <p><b>Code Quality and Testing:</b> Write clean, maintainable code with comprehensive unit tests. Perform code reviews and ensure adherence to best practices and coding standards. Write clean, maintainable code with comprehensive unit tests. Perform code reviews and ensure adherence to best practices and coding standards.</p>
          <p><b>Troubleshooting and Optimization:</b> Identify and resolve performance issues, debug complex problems, and optimize application performance for improved user experience and scalability.</p>
        </div>
        <h3>Required Education Skills</h3>
        <div className='python-description'>
          {/* full stack python developer education skills */}
          <p><b>Bachelor's Degree:</b> A degree in Computer Science, Software Engineering, or a related field.</p>
          <p><b>Proficiency in Python:</b> Strong knowledge of Python programming, including frameworks such as Django and Flask.</p>
          <p><b>Front-End Technologies:</b> Experience with HTML, CSS, and JavaScript frameworks (e.g., React, Angular).</p>
          <p><b>Database Management:</b> Skills in SQL and NoSQL databases like PostgreSQL, MySQL, and MongoDB.</p>
          <p><b>Version Control Systems:</b> Proficiency with Git for managing code changes and collaboration.</p>
        </div>
        {/* full stack python developer additional description */}
        <div className='python-description-info'>
            <p><b>Job Category:</b>Full Stack Python Developer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Musrambagh</p>
        </div>
      </div>
      {/* full stack python developer apply form */}
      <div className="python-apply-form">
        {/* full stack python developer apply form heading */}
        <h3>Apply for this position</h3>
        {/* full stack python developer apply form box */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name" required />
          
          <label>Email *</label>
          <input type="email" name="email" required />
          
          <label>Phone *</label>
          <input type="tel" name="phone" required />
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4" required></textarea>

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required />
          
          <button type="submit">Submit</button>
        </form>
        {/* Display response message */}
        {responseMessage && (
            <div className="response-message">
                <p>{responseMessage}</p>
            </div>
            )}
      </div>
    </div>
  )
}

export default FullStackPythonDeveloper
