import React from 'react';
import './WebsiteDevelopment.css';
import webdeveloping from '../../Assets/web_developing.gif';
import webservices from '../../Assets/website_services.gif';
import webprocess from '../../Assets/website-process.gif';
import webvisualization from '../../Assets/website_visualization.gif';
import webinstructions from '../../Assets/web-instructions.gif';

const WebsiteDevelopment = () => {
  return (
    // Website Development services page content 
    <div className='web_content'>
        <h5>Website Development</h5>
        {/* para content with image */}
        <div className='para_content'>
            <img className='img1' src={webdeveloping} alt="website_developing" />
            <p>Website development refers to the process of creating and maintaining websites. It encompasses various aspects such as web design, web content development, client-side/server-side scripting, and network security configuration. It involves both the design and technical aspects of building a functional and visually appealing website.</p>
        </div>  
        {/* benefits content with image */}
        <div className='benefits_content'>
            <h5>Benefits of Website Development:</h5>
            <img className='img2' src={webservices} alt="web_services" />
            <div className='benefits'>
                <p>A website provides a global platform for individuals and businesses to reach a wider audience beyond geographical constraints.</p>
                <p>Websites are accessible around the clock, allowing users to obtain information and services at their convenience.</p>
                <p>Websites can track user behavior, preferences, and feedback, providing valuable insights for improving products and services.</p>
                <p>Websites can be easily updated and scaled to accommodate growth, new features, and changing business needs.</p>
                <p>Digital marketing through websites is often more affordable compared to traditional methods, such as print or broadcast advertising.</p>
                <p>Having a well-designed and functional website helps businesses stand out from competitors and capture a larger market share.</p>
            </div>
        </div>    
        {/* usage content with image */}
        <div className='usage_content'>
            <h5>Usage of Website Development:</h5>
            <div className='usage'>
                <p>A website provides a global platform for individuals and businesses to reach a wider audience beyond geographical constraints.</p>
                <p>Websites are accessible around the clock, allowing users to obtain information and services at their convenience.</p>
                <p>Websites can track user behavior, preferences, and feedback, providing valuable insights for improving products and services.</p>
                <p>Websites can be easily updated and scaled to accommodate growth, new features, and changing business needs.</p>
                <p>Digital marketing through websites is often more affordable compared to traditional methods, such as print or broadcast advertising.</p>
                <p>Having a well-designed and functional website helps businesses stand out from competitors and capture a larger market share.</p>
            </div>
            <img className='img3' src={webprocess} alt="web_process" />
        </div>    
        {/* applications content with image */}
        <div className='applications_content'>
            <h5>Applications of Website Development:</h5>
            <img className='img4' src={webvisualization} alt="web_visualization" />
            <div className='applications'>
                <p>A website provides a global platform for individuals and businesses to reach a wider audience beyond geographical constraints.</p>
                <p>Websites are accessible around the clock, allowing users to obtain information and services at their convenience.</p>
                <p>Websites can track user behavior, preferences, and feedback, providing valuable insights for improving products and services.</p>
                <p>Websites can be easily updated and scaled to accommodate growth, new features, and changing business needs.</p>
                <p>Digital marketing through websites is often more affordable compared to traditional methods, such as print or broadcast advertising.</p>
                <p>Having a well-designed and functional website helps businesses stand out from competitors and capture a larger market share.</p>
            </div>
        </div>   
        {/* website development down image content */}
        <div className='down_image_content'>
            <img className='img5' src={webinstructions} alt="web_instructions" />
        </div>

    </div>
  )
}

export default WebsiteDevelopment
