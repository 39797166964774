import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/account'); // Redirect to login if no token
      return;
    }

    // Load user details from localStorage
    const storedUsername = localStorage.getItem('username');
    const storedEmail = localStorage.getItem('email');
    
    if (storedUsername && storedEmail) {
      setUsername(storedUsername);
      setEmail(storedEmail);
    } else {
      setUsername('Username not found');
      setEmail('Email not found');
    }

    setLoading(false); // Set loading to false when data is loaded
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    navigate('/account'); // Redirect to login page
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while fetching data
  }

  return (
    <div className="profile-page">
      <h1>Profile</h1>
      <h1><strong>Username:</strong> {username}</h1>
      <h1><strong>Email:</strong> {email}</h1><br /><br />
      <button onClick={handleLogout}>Logout</button><br /><br />
    </div>
  );
};

export default Profile;
